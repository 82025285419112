import { extendTheme } from "@chakra-ui/react"
// 2. Extend the theme to include custom colors, fonts, etc

const colors = {
  brand: {
    50: "#991120",
    100: "#991120",
    200: "#991120",
    300: "#991120",
    400: "#991120",
    500: "#991120",
    600: "#991120",
    700: "#991120",
    800: "#991120",
    900: "#991120",
  },
}

const theme = extendTheme({ colors })

export default theme;